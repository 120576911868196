import React from 'react';
import './App.css';
import IndexPage from './pages/IndexPage/IndexPage'
import Home from './pages/Home/Home'
import Empty from './pages/Empty/Empty'
import {HashRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import FactoryEntry from "./components/FactoryEntry/FactoryEntry";
import EntryLine from "./components/EntryLine/EntryLine";
import Staff from "./components/Staff/Staff";
import PunchIn from "./components/PunchIn/PunchIn";

//BrowserRouter


function App() {
    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route path="/" exact render={() => {
                        return <Redirect to="/index"/>
                    }
                    }/>
                    <Route path="/new_factory" exact render={() => {
                        return <Redirect to="/index"/>
                    }
                    }/>
                    <Route path="/index" component={IndexPage} />
                    <Route path="/home" component={Home} />
                    <Route path="/entry" component={FactoryEntry} />
                    <Route path="/line" component={EntryLine} />
                    <Route path="/staff" component={Staff} />
                    <Route path={"/punch_in"} component={PunchIn} />
                    <Route component={Empty} />
                </Switch>
            </Router>
        </div>
    );
}

export default App;
