import React,{Component} from 'react'
import "./emtpyStyle.scss"
export default class Empty extends Component{
    render() {
        return ( <div className="empty-wrapper">
           empty
        </div>
        )
    }

}