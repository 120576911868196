import React, {Component} from 'react';
import {Collapse, ImageViewer, NavBar, Tag} from "antd-mobile";

class PunchIn extends Component {
    render() {
        return (
            <div className={"punch-in-wrapper"}>
                <div className="nav">
                    <NavBar onBack={() => {
                        this.props.history.go(-1)
                    }}>打卡点及注意事项
                    </NavBar>
                </div>
                <div className="content">
                    <Collapse defaultActiveKey={['1']}>

                        <Collapse.Panel key='1' title='打卡点'>
                            <ul>
                                <li>
                                    生产车间一楼员工，
                                    <Tag color='primary'
                                         onClick={()=>{

                                         }}
                                    >一楼车间内指定打卡点打卡</Tag>。

                                </li>
                                <li>
                                    生产车间二楼员工，
                                    <Tag color='primary'
                                         onClick={()=>{

                                         }}
                                    >一楼车间内指定打卡点打卡</Tag>
                                    。

                                </li>
                                <li>
                                    仓库员工，
                                    <Tag color='primary'
                                         onClick={()=>{

                                         }}
                                    >一楼车间内指定打卡点打卡</Tag>
                                    。

                                </li>

                                <li>
                                    办公楼二三层员工，
                                    <Tag color='primary'
                                         onClick={()=>{

                                         }}
                                    >在各自楼层办公室门口打卡</Tag>。
                                </li>
                            </ul>
                        </Collapse.Panel>
                        <Collapse.Panel key='2' title='打卡注意事项'>
                            <ul>
                                <li>生产车间及仓库员工上班需先更换衣服，更换完成后进入一楼车间打卡上班，下班需先在车间打卡，而后更换衣服下班。</li>
                                <li>共有三个点：一楼车间、二楼车间及仓库员工在一楼车间内更衣室门口打卡；办公二层员工在办公室门口打卡；办公三层员工在办公室门口打卡</li>
                            </ul>
                        </Collapse.Panel>

                    </Collapse>


                </div>
            </div>
        );
    }
}

export default PunchIn;