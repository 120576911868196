import React, {Component} from 'react'
import './css.scss'
import {AutoCenter, Button, Image, ImageViewer, SafeArea, Tag} from 'antd-mobile'
import {EnvironmentOutline, LinkOutline} from "antd-mobile-icons";
import imgLineB from "../../assets/images/lineb.jpg";
import imgBo from "../../assets/images/bo3.jpg"
import imgBoPc from "../../assets/images/bo.jpg"
import  logo from "../../assets/images/logo2.jpg"

export default class IndexPage extends Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            url: imgBo,
            isMobile: true


        };

    }

    calIsMobile = () => {
        return /phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone/i.test(navigator.userAgent)
    }

    componentDidMount = async () => {

        if (this.calIsMobile()) {
            console.log("mobile")
            this.setState({
                url: imgBo,
                isMobile: true
            });
        } else {
            this.setState({
                isMobile: false,
                url: imgBoPc,
            })
            console.log("pc")
        }
    }


    render() {
        let {url, isMobile} = this.state

        return (
            <div className="login-wrapper">
                <SafeArea position={"top"}></SafeArea>

               {/* {(!isMobile) && <AutoCenter className={"btn-center"}>
                    <div className="title">
                        苏州科士达新厂相关规则
                    </div>

                </AutoCenter>}
                {(!isMobile) && <AutoCenter className={"btn-center"}>
                    <div className="sub-title">
                        苏州科士达管理部 2024年4月
                    </div>
                </AutoCenter>}*/}
                <AutoCenter className={"btn-center"}>
                    <div className="btn-in">
                        {/*     <Button onClick={()=>{
                            this.props.history.push("home")
                        }}>点此进入<LinkOutline /></Button>*/}
                        <Tag
                            style={{fontSize: 17}}
                            className={"animate__heartBeat animate__repeat-3"}
                            color='success'
                            onClick={() => {
                                this.props.history.push("/home")
                            }}
                        >点此进入<LinkOutline/></Tag>
                    </div>
                </AutoCenter>
                {
                    (isMobile)&&<div className={"logo"}>
                        <img src={logo} alt="" />
                    </div>
                }

                <div className="bg-img">
                    <Image src={url}  height={"100vh"} fit={isMobile? "cover":"contain"}/>
                </div>

                {/*   <AutoCenter>
                    <div className="footer">
                        <Image src={logo}  alt={"logo"} width={"26vw"}/>
                    </div>
                </AutoCenter>*/}

            </div>
        )
    }

}