import React, {Component} from 'react';
import {Collapse, ImageViewer, NavBar, Tag} from "antd-mobile";
import imgLineB from "../../assets/images/lineb.jpg"
import imgLineN from "../../assets/images/linen.jpg"
import {EnvironmentOutline} from "antd-mobile-icons";
import 'animate.css';

class EntryLine extends Component {
    render() {
        return (
            <div className={"entry-line-wrapper"}>
                <div className="nav">
                    <NavBar onBack={() => {
                        this.props.history.go(-1)
                    }}>进入工厂室内区域的说明
                    </NavBar>
                </div>
                <div className="content">
                    <Collapse defaultActiveKey={['1']}>
                        <Collapse.Panel key='1' title='车间、仓库员工上下班路线'>
                            <ul>
                                <li>驾车通勤的员工，按规定停放好车辆后，从员工出入口进到厂区内，因根据指示从
                                    <Tag
                                        className={"animate__heartBeat animate__repeat-3"}
                                        style={{cursor:"pointer"}}
                                        color='success'
                                         onClick={() => {
                                             ImageViewer.show({
                                                 image: imgLineB
                                             })
                                         }}
                                    >【更衣室北门】<EnvironmentOutline/></Tag>
                                    进，按规定更衣后从
                                    <Tag color='success' style={{cursor:"pointer"}}
                                         onClick={() => {
                                             ImageViewer.show({
                                                 image: imgLineN
                                             })
                                         }}
                                    >【更衣室南门】<EnvironmentOutline/></Tag>
                                    进入一楼车间生产区域；
                                </li>
                                <li>驾驶电瓶车、自行车的员工，按规定将电瓶车停放到地下车库后，从车库人行通道上楼后，进入
                                    <Tag color='success'
                                         style={{cursor:"pointer"}}
                                         onClick={() => {
                                             ImageViewer.show({
                                                 image: imgLineB
                                             })
                                         }}
                                    >【更衣室北门】<EnvironmentOutline/></Tag>
                                    。按规定更衣后，从 <Tag color='success'
                                                          style={{cursor:"pointer"}}
                                                          onClick={() => {
                                                              ImageViewer.show({
                                                                  image: imgLineN
                                                              })
                                                          }}
                                    >【更衣室南门】<EnvironmentOutline/></Tag> 进入一楼车间生产区域。
                                </li>
                                <li>步行通勤的员工，从员工出入口进到厂区内，因根据指示从
                                    <Tag color='success'
                                         style={{cursor:"pointer"}}
                                         onClick={() => {
                                             ImageViewer.show({
                                                 image: imgLineB
                                             })
                                         }}
                                    >【更衣室北门】<EnvironmentOutline/></Tag>
                                    进，按规定更衣后从 <Tag color='success'
                                                           style={{cursor:"pointer"}}
                                                           onClick={() => {
                                                               ImageViewer.show({
                                                                   image: imgLineN
                                                               })
                                                           }}
                                    >【更衣室南门】<EnvironmentOutline/></Tag> 进入一楼车间生产区域；
                                </li>
                                <li>需要去二楼车间的员工，同样按上述要求更衣后，途径一楼车间后，从楼梯间上二楼，并进入车间。</li>
                                <li>二楼药房员工，在二楼药房门口换鞋后进入车间。</li>
                                <li>需要离开工厂或下班时，按照以上路线的反方向，更衣后从经更衣室后从员工入口离开。
                                </li>
                            </ul>
                        </Collapse.Panel>

                        <Collapse.Panel key='2' title='办公室人员上下班路线'>
                            <ul>
                                <li>
                                    访客、以及办公室人员可从公司正门进入大厅后，乘用电梯后刷门禁，进入二楼或三楼办公区域
                                </li>
                            </ul>
                        </Collapse.Panel>

                    </Collapse>
                </div>
            </div>
        );
    }
}

export default EntryLine;