import React, {Component} from 'react';
import {Button, Collapse, ImageViewer, NavBar, Popover, Tag} from "antd-mobile";
import {EnvironmentOutline} from "antd-mobile-icons";
import imgB from "../../assets/images/b.jpg"
import imgN from "../../assets/images/n.jpg"
import imgD from "../../assets/images/d.jpg"
import imgGd from "../../assets/images/gd.jpg"
import imgGs from "../../assets/images/gs.jpg"
import imgWl from "../../assets/images/wl.jpg"
import imgYg from "../../assets/images/yg.jpg"
import imgDc from "../../assets/images/dc.jpg"
import 'animate.css';

import "./entry.scss"
class FactoryEntry extends Component {
    render() {
        return (
            <div className={"factory-entry-wrapper"}  >
                <div className="nav">
                    <NavBar onBack={() => {
                        this.props.history.go(-1)
                    }}>关于新工厂出入口的说明
                    </NavBar>
                </div>
                <div className="content">
                    <Collapse defaultActiveKey={['1']}>
                        <Collapse.Panel key='1' title='使用电瓶车、自行车出入的人员'>
                            <ul>
                                <li>请从
                                        <Tag color='success'
                                             style={{cursor:"pointer"}}
                                             className={"animate__heartBeat animate__repeat-3"}
                                             onClick={() => {
                                                 ImageViewer.show({
                                                     image: imgB
                                                 })
                                             }}
                                        >【北入口】<EnvironmentOutline/></Tag>
                                     进出；

                                </li>
                                <li>
                                    电瓶车与自行车进入工厂后，需要从
                                    <Tag
                                        style={{cursor:"pointer"}}
                                        color='success' onClick={() => {
                                        ImageViewer.show({
                                            image: imgD
                                        })
                                    }}>【电瓶车入口】<EnvironmentOutline/></Tag> 进入到地下车库，并按序整齐停放；

                                </li>
                                <li>
                                    进出地库时，请先<Tag style={{cursor:"pointer"}} color={"danger"}>停车</Tag>，观察入口设置的<Tag
                                    color={"danger"}>信号灯</Tag>，且确认安全后再次启动车辆进出。
                                </li>
                                <li>
                                    地下车库禁止一切充电行为，需要充电的车辆请停放到地面指定的
                                    <Tag color='success' style={{cursor:"pointer"}}
                                         onClick={() => {
                                             ImageViewer.show({
                                                 image: imgDc
                                             })
                                         }}
                                    >【电瓶车充电区域】<EnvironmentOutline/></Tag>

                                </li>
                                <li>
                                    由于充电位及其有限，如非必要请尽量避免在公司充电。
                                </li>
                            </ul>
                        </Collapse.Panel>
                        <Collapse.Panel key='2' title='步行出入的人员'>
                            <ul>
                                <li>步行人员请从 <Tag color='success' style={{cursor:"pointer"}}
                                onClick={()=>{
                                    ImageViewer.show({
                                        image:imgB
                                    })

                                }}
                                >【北入口】<EnvironmentOutline/></Tag> 进出；</li>
                                <li>非本公司员工请在保安处完成登记后进入；</li>
                            </ul>
                        </Collapse.Panel>
                        <Collapse.Panel key='3' title='驾驶汽车出入的人员'>
                            <ul>
                                <li>且已获取公司固定车位的人员，请从 <Tag style={{cursor:"pointer"}}
                                    color='success'
                                    onClick={()=>{
                                        ImageViewer.show({
                                            image:imgB
                                        })

                                    }}
                                >【北入口】<EnvironmentOutline/></Tag> 进出；并停放在
                                    <Tag color='success' style={{cursor:"pointer"}}
                                         onClick={()=>{
                                             ImageViewer.show({
                                                 image:imgGd
                                             })

                                         }}
                                    >【管理层固定车位】<EnvironmentOutline/></Tag> 区域内；
                                </li>
                                <li>公司车辆请从 <Tag color='success' style={{cursor:"pointer"}}
                                                        onClick={()=>{
                                                            ImageViewer.show({
                                                                image:imgB
                                                            })
                                                        }}
                                >【北入口】<EnvironmentOutline/></Tag> 进出；并停放在
                                    <Tag color='success' style={{cursor:"pointer"}}
                                         onClick={()=>{
                                             ImageViewer.show({
                                                 image:imgGs
                                             })

                                         }}
                                    >【公司车辆停放车位】<EnvironmentOutline/></Tag> 区域内
                                </li>
                                <li>访客请在保安处登记后，在保安处获取指定车位牌后，按车位牌指定的车位号停放在
                                    <Tag color='success' style={{cursor:"pointer"}}
                                         onClick={()=>{
                                             ImageViewer.show({
                                                 image:imgWl
                                             })

                                         }}
                                    >【外来访客停车位】<EnvironmentOutline/></Tag>
                                    区域
                                </li>
                                <li>其他没有固定车位的所有员工车辆，均请从
                                    <Tag color='success' style={{cursor:"pointer"}}
                                         onClick={() => {
                                             ImageViewer.show({
                                                 image: imgN
                                             })
                                         }}
                                    >【南入口】<EnvironmentOutline/></Tag> 进出，并停放在
                                    <Tag color='success' style={{cursor:"pointer"}}
                                         onClick={()=>{
                                             ImageViewer.show({
                                                 image:imgYg
                                             })

                                         }}
                                    >【员工非固定车位】<EnvironmentOutline/></Tag> 区域
                                </li>
                                <li>请严格遵守车位停放区域规定，严谨占用固定车位以及访客车位；</li>
                                <li>车辆停放时，请确保车头向前，车身摆正。严禁溢出车位线。</li>
                            </ul>
                        </Collapse.Panel>
                        <Collapse.Panel key='4' title='驾驶货车出入的人员'>
                            <ul>
                                <li>所有的货车均从 <Tag style={{cursor:"pointer"}}
                                    color='success'
                                    onClick={()=>{
                                        ImageViewer.show({
                                            image:imgN
                                        })

                                    }}
                                >【南入口】<EnvironmentOutline/></Tag> 进出；外来车辆需先下车做好登记；
                                </li>
                                <li>货车进入工厂区域，应严格遵循限速以及道路标志行驶要求；</li>
                                <li>货车应停放在指定位置，不得占用道路;</li>
                            </ul>
                        </Collapse.Panel>
                    </Collapse>
                </div>
            </div>
        );
    }
}

export default FactoryEntry;