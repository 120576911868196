import React, {Component} from 'react';
import {Collapse, ImageViewer, NavBar, Tag} from "antd-mobile";
import {SmileOutline} from "antd-mobile-icons";

import imgS1 from "../../assets/images/s1.jpg"
import imgS2 from "../../assets/images/s2.jpg"
import imgS3 from "../../assets/images/s3.jpg"
import imgS4 from "../../assets/images/s4.jpg"
import 'animate.css';

class Staff extends Component {
    render() {
        return (
            <div className={"staff-wrapper"}>
                <div className="nav">
                    <NavBar onBack={() => {
                        this.props.history.go(-1)
                    }}>员工上班时的着装要求
                    </NavBar>
                </div>
                <div className="content">
                    <Collapse defaultActiveKey={['1']}>
                        <Collapse.Panel key='1' title='车间、仓库员工上班着装要求'>
                            <ul>
                                <li>车间员工工作前必须更换统一的<Tag color='warning'>工衣</Tag> 、<Tag
                                    color='warning'>工鞋</Tag>；
                                </li>
                                <li>根据车间需要 <Tag color='warning'>佩戴耳塞</Tag>；</li>
                                <li>换下的个人服装、鞋帽以及私人物品，应放柜子，并锁好柜门；</li>
                                <li>下班时，必须换下工衣工鞋、帽子。</li>
                                <li ><Tag className={"animate__heartBeat animate__repeat-3"} color={"success"}
                                          style={{cursor:"pointer"}}
                                         onClick={() => {
                                             ImageViewer.Multi.show({
                                                 images: [imgS1,imgS2,imgS3,imgS4]
                                             })
                                         }}
                                >查看规范示例<SmileOutline /></Tag></li>
                            </ul>
                        </Collapse.Panel>
                        <Collapse.Panel key='2' title='办公室人员着装要求'>
                            <ul>
                                <li>2楼、3楼办公的员工，上下班无需更衣、换鞋；</li>
                                <li>如需要进入车间区域，请<Tag style={{cursor:"pointer"}} color='warning'>根据车间区域要求换鞋更衣</Tag>;</li>
                            </ul>

                        </Collapse.Panel>
                    </Collapse>

                </div>
            </div>
        );
    }
}

export default Staff;