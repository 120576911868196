import React, {Component} from 'react'

import './home.scss'
import {List, SafeArea, ImageViewer, Tag, Toast} from "antd-mobile";
import {
    ClockCircleOutline, LinkOutline, SetOutline, SmileOutline, TeamOutline, TruckOutline,

} from "antd-mobile-icons";

import factoryImg from "../../assets/images/factory.jpg"

export default class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showFactoryImg: false
        }

    }


    render() {
        let {showFactoryImg} = this.state
        return (<div className="home-wrapper" >
                <SafeArea position='top'/>
                <List header='详细说明和图示'>
                    <List.Item prefix={<SetOutline/>}
                               onClick={() => {
                                   this.setState({
                                       showFactoryImg: true
                                   })
                               }}
                    >公司区域整体分布图</List.Item>
                    <List.Item prefix={<TruckOutline/>}
                               onClick={() => {
                                   this.props.history.push("/entry")
                               }}
                    >各类车辆、行人出入口说明</List.Item>
                    <List.Item prefix={<TeamOutline/>}
                               onClick={() => {
                                   this.props.history.push("/line")
                               }}
                    >员工以及访客进入公司路线说明</List.Item>
                    <List.Item prefix={<ClockCircleOutline/>}
                               onClick={() => {
                                   this.props.history.push("/punch_in")
                               }}
                    >员工上下班打卡点说明</List.Item>
                    <List.Item prefix={<SmileOutline/>}
                               onClick={() => {
                                   this.props.history.push("/staff")
                               }}
                    >员工上班着装要求说明</List.Item>

                </List>
                <div className="image-collection">
                    <ImageViewer
                        classNames={{
                            mask: 'customize-mask',
                            body: 'customize-body',
                        }}
                        image={factoryImg}
                        visible={showFactoryImg}
                        onClose={() => {
                            this.setState({
                                showFactoryImg: false
                            })
                        }}
                    />
                </div>
            </div>
        )
    }
}